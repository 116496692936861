<template>
	<Layout>
		<Header />
		<filter-default enable-search />
		<div v-if="user" class="columns is-multiline">
			<div v-for="t in transactions" :key="`transac-${t.id}`" class="column is-12-mobile is-6-tablet is-4-desktop">
				<Transaction :transaction="t" :userId="user.id" :userMaster="user.permission_id == 1" />
			</div>
		</div>
	</Layout>
</template>

<script>
import Layout from '@/layouts/Default'
import Header from '@/components/Header'
import Transaction from '@/components/Transaction'
import FilterDefault from '@/components/FilterDefault'
import Api from '@/services/api'
import { mapState } from 'vuex'

export default {
	name: 'Transactions',
	components: {
		Layout,
		Header,
		FilterDefault,
		Transaction
	},
	data() {
		return {
			transactions: []
		}
	},
	mounted() {
		this.getTransactions()
	},
	methods: {
		getTransactions() {
			Api.get('transactions/findAll')
				.then(({ data }) => {
					this.transactions = data
				})
		}
	},
	computed: {
		...mapState('user', [
			'user'
		])
	}
}
</script>
